<template>
  <div class="card">
    <div  v-if="seekable"  class="card-header">
      <div class="row align-items-center">
        <div class="col">
          <div class="input-group input-group-flush input-group-merge input-group-reverse">
            <input class="form-control list-search" type="search"
                   v-model="search"
                   placeholder="Buscar" v-on:keyup.enter="fetchData(1, search)">
            <span class="input-group-text">
                <i class="fe fe-search"></i>
            </span>
          </div>
        </div>
        <div v-if="filterable" class="col-auto">
          <div class="dropdown">
            <!-- Toggle -->
            <button class="btn btn-sm btn-white" type="button" data-bs-toggle="dropdown"
                    data-bs-auto-close="outside" aria-haspopup="true"
                    aria-expanded="false">
              <i class="fe fe-sliders me-1"></i> Filtrar
              <slot name="active-filter"></slot>
            </button>

            <!-- Menu -->
            <div class="dropdown-menu dropdown-menu-end dropdown-menu-card">
              <slot name="filter"></slot>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="table-responsive">
      <table class="table table-sm table-hover table-nowrap card-table">
        <thead>
        <tr>
          <slot name="columns"></slot>
        </tr>
        </thead>
        <tbody class="list fs-base">
        <slot name="content"></slot>
        </tbody>
      </table>
    </div>
    <slot name="content1" class="second-content"></slot>
    <div class="card-footer d-flex justify-content-between">
      <ul class="list-pagination-prev pagination pagination-tabs card-pagination">
        <li class="page-item">
          <button @click="previousPage()"
                  :disabled="!previousPageExists"
                  class="page-link ps-0 pe-4 border-end">
            <i class="fe fe-arrow-left me-1"></i> Anterior
          </button>
        </li>
      </ul>
      <ul class="list-pagination pagination pagination-tabs card-pagination">
        <li v-for="page in pagination"
            :class="page === currentPage ? 'active' : ''"
            :key="page">
          <button class="page" @click="fetchData(page, search)">
            {{ page }}
          </button>
        </li>
      </ul>

      <!-- Pagination (next) -->
      <ul class="list-pagination-next pagination pagination-tabs card-pagination">
        <li class="page-item">
          <button @click="nextPage()"
                  :disabled="!nextPageExists"
                  class="page-link ps-4 pe-0 border-start">
            Próximo <i class="fe fe-arrow-right ms-1"></i>
          </button>
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
export default {
  name: "PaginationTable",

  props: {
    action: {required: true,},
    meta: {required: true,},
    seekable: {default: true, required: false},
    filterable: {default: true, required: false}
  },

  mounted() {
    this.fetchData(1);
  },

  data() {
    return {
      search: '',
    }
  },

  computed: {
    currentPage() {
      return this.meta?.pagination?.current_page;
    },

    totalPages() {
      return this.meta?.pagination?.total_pages;
    },

    nextPageExists() {
      return this.totalPages >= this.currentPage + 1;
    },

    previousPageExists() {
      return this.currentPage > 1;
    },

    pagination() {
      let pages = [];

      for (let i = 1; i <= this.totalPages; i++) {
        pages.push(i);
      }

      return pages;
    }
  },

  methods: {
    nextPage() {
      if (this.nextPageExists) {
        this.fetchData(this.currentPage + 1);
      }
    },

    previousPage() {
      if (this.previousPageExists) {
        this.fetchData(this.currentPage - 1);
      }
    },

    fetchData(page, search) {
      this.$store.dispatch(this.action, {page, search});
    },
  },
}
</script>

<style scoped>
button[disabled]:hover {
  color: #95aac9;
}
</style>